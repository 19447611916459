import React, { FunctionComponent } from "react"
import { Layout } from "../components/layout/Layout"

const PastEventsPage: FunctionComponent = () => {

  return (
    <Layout>
      <h1>Misfire 1</h1>
      <div>
          <a href="https://smash.gg/tournament/ssbu-misfire/details" target="_blank">Smash GG Overview</a>
          <br/>
          <a href="https://smash.gg/tournament/ssbu-misfire/attendees" target="_blank">Attendees</a>
          <br/>
          <a href="https://smash.gg/tournament/ssbu-misfire/event/ultimate-singles-bracket/brackets/828890/1325557" target="_blank">Swiss Pools (3 Rounds)</a>
          <br/>
          <a href="https://smash.gg/tournament/ssbu-misfire/event/ultimate-singles-bracket/brackets/828884/1325550" target="_blank">Double-Elimination Bracket</a>
          <br/>
        </div>
    </Layout>
  )
}

export default PastEventsPage
